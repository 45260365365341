@import "../../styles/variables.scss";

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: $mobile_navbar_height;
  display: flex;
  align-items: center;
  z-index: 1;
  transition: background-color 200ms linear;

  .branding {
    height: 100%;
    display: flex;
    text-decoration: none;
    align-items: center;
    z-index: 999;

    .logo {
      padding-left: $page-margin;
    }

    .text {
      font-family: Marcellus SC, serif;
      font-size: 18px;
      color: white;
      padding-left: 6px;
      white-space: pre-wrap;
      margin: 0;
    }
  }

  .hamburger {
    display: flex;
    align-items: center;
    margin-left: auto;
    z-index: 999;
    cursor: pointer;
    height: 100%;
    padding-right: $page-margin;
  }

  .mobile-nav {
    position: fixed;
    background: $color-main;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.35s, visibility 0.35s, height 0.35s;

    &.open {
      opacity: 0.99;
      visibility: visible;
      height: 100%;
    }

    nav {
      position: relative;
      height: calc(100vh - #{$mobile_navbar_height});
      top: $mobile_navbar_height;
      font-size: 32px;
      font-family: Marcellus SC, serif;
      font-weight: 400;
      text-align: left;
      padding: 0px 24px;
      overflow: scroll;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      display: inline-block;
      position: relative;
      height: 100%;
      width: 100%;

      li {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        height: auto;
        padding-bottom: 16px;
        position: relative;
        opacity: 100%;
        cursor: pointer;

        .chevron {
          padding-left: 4px;
          height: 15px;
        }

        a {
          display: block;
          position: relative;
          color: white;
          text-decoration: none;
          overflow: hidden;
          width: 100%;
        }
      }

      &.sub-list {
        height: auto;
        width: 100%;
        border-style: solid;
        padding: 8px 0px;
        border-width: 1px;
        border-radius: 4px;
        border-color: #cc9232;
        font-size: 18px;

        li {
          padding: 4px 0 4px 16px;
        }
      }
    }
  }
}

.navbar.scrolled {
  background-color: $color-main;
}
