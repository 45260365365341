$color-main: #1a2b43;
$color-main-light: #2f4d77;
$color-gold: #2f4d77;
$color-text: white;

$mobile_navbar_height: 80px;

$mobile_footer_height: 21px;

$page-margin: 16px;
