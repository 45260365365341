@import url("https://fonts.googleapis.com/css2?family=Marcellus&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Marcellus&family=Marcellus+SC&display=swap");
@import "~bootstrap/scss/bootstrap";

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: Marcellus, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a2b43;
}
